<template>
    <voffice-popup-box :trigger-id="triggerId"
        class="full-xxs"
        :apply-label="applyLabel"
        :close-btn-disabled="closeBtnDisabled"
        @closed="onClosed">

        <div class="datepicker-trigger">

            <airbnb-style-datepicker :trigger-element-id="triggerId"
                :close-after-select="false"
                :inline="true"
                :show-shortcuts-menu-trigger="false"
                :show-action-buttons="false"
                :offset-y="10"
                :mode="'range'"
                :fullscreen-mobile="false"
                :disabled-dates="disabledDays"
                :customized-dates="calClasses"
                :min-date="minDate"
                :end-date="maxDate"
                :date-one="from"
                :date-two="till"
                @closed="onClosed"
                :mobile-header="mobileHeader"
                @date-one-selected="onFromSelected"
                @date-two-selected="onTillSelected" />

            <div class="datepicker-ext-row"
                v-if="possibleNights">
                <span>{{voMsg('min.label')}}</span>
                <select v-model="minNights">
                    <option v-for="option in possibleNights"
                        v-bind:value="option.value">
                        {{ option.text }}
                    </option>
                </select>
                <span>{{voMsg('max.label')}}</span>
                <select v-model="maxNights">
                    <option v-for="option in possibleNights"
                        v-bind:value="option.value">
                        {{ option.text }}
                    </option>
                </select>

            </div>

        </div>
    </voffice-popup-box>

</template>

<script>
    import {
    	isBlank
    }
    from 'voUtils/tools.js';
    import V from 'voUtils/V.js'


    import DateEx from 'voUtils/DateEx.js'

    export default {
    	voVueComponent: 'voffice-daterange-ext',
    	voMsgCodes: [
    		'weekdayslist.short',
    		'monthlist.full',
    		'weekdayslist',
    		'night.pl',
    		'min.label',
    		'max.label',
    	],
    	data() {
    		return {
    			needTill: false,
    			nextArrivalDate: undefined,
    			disabledArrivalDays: [],
    			disabledDepartureDays: [],
    			minNights: undefined,
    			maxNights: undefined,
    			calClasses: [],
    			cal: undefined
    		}
    	},
    	props: {
    		from: String,
    		till: String,
    		nights_min: Number,
    		nights_max: Number,
    		triggerId: String,
    		fullscreenMobile: Boolean,
    		applyLabel: String,
    	},
    	computed: {
    		disabledDays: function() {
    			return this.needTill ? this.disabledDepartureDays : this.disabledArrivalDays
    		},
    		closeBtnDisabled: function() {
    			return !this.from || !this.till
    		},
    		possibleNights: function() {
    			if (this.from && this.till) {
    				let days = DateEx.getDays(this.till, this.from)
    				let result = []
    				for (let i = 1; i <= days; i++) {
    					result.push({
    						value: i,
    						text: i + ' ' + this.voMsgPl('night.pl', i)
    					})
    				}
    				console.log("result", result, days)
    				return result
    			} else {
    				return undefined
    			}

    		},
    		mobileHeader: function() {
    			if (this.needTill) {
    				return this.voMsg("daterange.till");
    			} else {
    				return this.voMsg("daterange.from");
    			}
    		},
    		minDate: function() {
    			if (this.needTill) {
    				return DateEx.plusDays(this.from, 1);
    			} else {
    				return DateEx.interfaceFormat(DateEx.getToday());
    			}

    		},
    		maxDate: function() {
    			if (this.needTill) {
    				return this.nextArrivalDate;
    			} else {
    				return undefined;
    			}

    		}

    	},

    	methods: {
    		onClosed: function() {

    			setTimeout(() => {
    				this.$emit('closed');
    			}, 50);
    		},
    		onFromSelected: function(d) {
    			this.needTill = true;
    			this.$emit('update:from', d);
    			this.$emit('update:till', undefined);


    		},
    		onTillSelected: function(d) {
    			this.needTill = isBlank(d) && this.from;
    			this.$emit('update:till', d);
    			setTimeout(() => {
    				this.$emit('on-tilldate-selected');
    			}, 50);
    		},
    		updateSelectedNights: function() {
    			if (this.from && this.till) {
    				if (!this.nights_min) {
    					this.minNights = DateEx.getDays(this.till, this.from)
    				} else {
    					this.minNights = this.nights_min;
    				}
    				if (!this.nights_max) {
    					this.maxNights = this.minNights
    				} else {
    					this.maxNights = this.nights_max
    				}

    			}


    		}
    	},
    	watch: {
    		from: function() {
    			this.updateSelectedNights()
    		},
    		till: function() {
    			this.updateSelectedNights()
    		},
    		minNights: function() {
    			this.$emit('update:nights_min', this.minNights);
    			if (this.maxNights && this.maxNights < this.minNights) {
    				this.maxNights = this.minNights
    			}
    		},
    		maxNights: function() {
    			this.$emit('update:nights_max', this.maxNights);
    			if (this.minNights && this.minNights > this.maxNights) {
    				this.minNights = this.maxNights
    			}
    		},


    	}
    }
</script>

<style scoped>
    .datepicker-trigger {
    	--cal_not_available: #cd7898;
    }

    .asd__day--not-available {
    	background: var(--cal_not_available) !important;
    }

    .asd__day--arrival {
    	background: linear-gradient(90deg, white 70%, var(--cal_not_available) 80%);
    }

    .asd__day--departure {
    	background: linear-gradient(90deg, var(--cal_not_available) 20%, white 30%);
    }
</style>